import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-loading-skeleton/dist/skeleton.css'
import App from './App';
import { MyContext } from './components/theme-context';
import { CookiesProvider } from "react-cookie";
import axios from 'axios';


axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers["Authorization"] = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers["Authorization"] = token;
    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.replace("/loginauth");
    }
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CookiesProvider>
    <MyContext>
      <App />
    </MyContext>
  </CookiesProvider>
);