import { redirect } from "react-router-dom";

function getAuthToken() {
  const token = localStorage.getItem("token");
  console.log("hello");
  if (!token) {
    return null;
  }
  return token;
}

export function CheckAuthLoader() {

  const token = getAuthToken();
  if (!token) {
    return redirect("/loginauth");
  }
  return null;
}
