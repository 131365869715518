import React, { useState, useEffect } from 'react';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useCookies } from "react-cookie";
import axios from 'axios';



export const MyContextData = React.createContext(null); // null is the default value


const fetchPageTheme = async () => {

    var config = {
        method: 'get',
        url: process.env.REACT_APP_DB_API_DOMAIN + 'getthemedetails?id=1',
        // url: 'http://localhost/mobile/api/theme.php',
        //   headers: { 
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Credentials':true
        //   },
        // data: data
    };
    return await axios(config).then((data) => {

        return data.data.data[0];
    });
}


const fetchToken = async () => {

    var config = {
        method: 'get',
        url: process.env.REACT_APP_DB_API_DOMAIN + 'createtoken',
        // url: 'http://localhost/mobile/api/theme.php',
        //   headers: { 
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Credentials':true
        //   },
        // data: data
    };
    return await axios(config).then((data) => {

        return data.data;
    });
}


export function MyContext(props) {
    const [cookies, setCookie] = useCookies(["theme"]);
    const [tkncookies, setTkncookie] = useCookies(["tknath"]);
    const [myState, setMyState] = useState({
        theme: {},
        token: null
    });

    useEffect(() => {
        if (!cookies.theme) {
            fetchPageTheme().then((data) => {
                const d = data;
                var date = new Date();
                var year = date.getFullYear();
                var month = date.getMonth();
                var day = date.getDate();
                date = new Date(year + 1, month, day);
                setCookie("theme", d, {
                    path: "/",
                    expires: date
                });
                fetchToken().then((data) => {
                    setMyState({
                        theme: d,
                        token: data
                    });
                    setCookie("tknath", data, {
                        path: "/",
                        expires: date
                    });
                })
            })
        }
        else {
            var date = new Date();
            var year = date.getFullYear();
            var month = date.getMonth();
            var day = date.getDate();
            date = new Date(year + 1, month, day);
            setMyState({
                theme: cookies.theme,
                token: null
            });
            fetchToken().then((data) => {
                setMyState({
                    theme: cookies.theme,
                    token: data
                });
                setCookie("tknath", data, {
                    path: "/",
                    expires: date
                });
            })
        }
    }, []);


    return (
        <MyContextData.Provider
            value={myState} // value of your context
        >
            <HelmetProvider>
                <Helmet>
                    <style type="text/css">{`
                            :root {
                                --primary: `+ (!myState ? "" : myState.theme.td_primaryclr) + `;
                                --secondary: `+ (!myState ? "" : myState.theme.td_secondryclr) + `;
                                --btnbg: `+ (!myState ? "" : myState.theme.td_primaryclr) + `;
                                --header: `+ (!myState ? "" : myState.theme.td_primaryclr) + `;
                                --primtxtclr: `+ (!myState ? "" : myState.theme.td_primaryclr) + `;
                                --bcgsecttxtclr: `+ (!myState ? "" : myState.theme.td_secondryclr) + `;
                            }
                        `}</style>
                </Helmet>
            </HelmetProvider>
            {props.children}
        </MyContextData.Provider>
    );
}
