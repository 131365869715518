import React, { Suspense, lazy } from "react";

import { Layout1, Layout2 } from "./layouts";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import { CheckAuthLoader } from "./components/CheckAuthLoader";

const NotFound = lazy(() => import("./views/404"));
const ComingSoon = lazy(() => import("./views/comingsoon"));

// import Head from './components/head';
// import Headers from './components/header';
// import NavSidebar from './components/navbar';
// import Foot from './components/foot';
// import Footer from './components/footer';
// import NotFound from './views/404';
// import ComingSoon from './views/comingsoon';

const Home = lazy(() => import("./views/home/index"));
const Product = lazy(() => import("./routes/product"));
const ProdList = lazy(() => import("./views/products/list"));

const Property = lazy(() => import("./views/post/property"));
const Contact = lazy(() => import("./views/home/Contactpage"));
const Login = lazy(() => import("./views/login/Login"));
const LeadList = lazy(() => import("./views/leadList/LeadList"));
// import Home from './views/home/index';
// import Product from './routes/product';
// import ProdList from './views/products/list';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={
          <Suspense
            fallback={
              <div className="loader">
                <div>
                  <img src="/assets/loader.gif" />
                </div>
              </div>
            }
          >
            <Layout1 />
          </Suspense>
        }
      >
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="buy/*">
          <Route path="projects/*">
            <Route path=":seourl" element={<ProdList />} />
          </Route>
          <Route path="neral/*" element={<Product />} />
          <Route path="*" element={<NotFound />} status={404} />
        </Route>
        <Route path="/comingsoon" element={<ComingSoon />} />

        <Route path="*" element={<NotFound />} status={404} />
      </Route>
      <Route
        path="/loginauth"
        element={
          <Suspense
            fallback={
              <div className="loader">
                <div>
                  <img src="/assets/loader.gif" />
                </div>
              </div>
            }
          >
            <Layout2 />
          </Suspense>
        }
      >
        <Route index element={<Login />} />
      </Route>
      <Route
        path="/post/"
        element={
          <Suspense
            fallback={
              <div className="loader">
                <div>
                  <img src="/assets/loader/loader.gif" />
                </div>
              </div>
            }
          >
            <Layout2 />
          </Suspense>
        }
      >
        <Route path="property" element={<Property />} />
      </Route>

      <Route
        loader={CheckAuthLoader}
        path="/leadauth-list"
        element={
          <Suspense
            fallback={
              <div className="loader">
                <div>
                  <img src="/assets/loader/loader.gif" />
                </div>
              </div>
            }
          >
            <LeadList />
          </Suspense>
        }
      />
    </>
  )
);

function FrontRoute() {
  return <RouterProvider router={router} />;
}

export default FrontRoute;
