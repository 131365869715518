import FrontRoute from './route';


function App() {
  return (
    <FrontRoute />
  );
}

export default App;
