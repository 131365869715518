import React, { Suspense, lazy }from "react";
import { Outlet } from "react-router-dom";
const Headers = lazy(() => import('./components/header'));
const NavSidebar = lazy(() => import('./components/navbar'));
const Foot = lazy(() => import('./components/foot'));
const Footer = lazy(() => import('./components/footer'));

const Layout1 = (props) => {
    return (
        <>
            <Headers />
            <NavSidebar />
            <Outlet/>
            <Footer />
            <Foot />
        </>
    )
}

const Layout2 = (props) => {
    return (
        <>
            <Outlet/>
        </>
    )
}

export {Layout1, Layout2}